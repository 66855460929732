import React from "react";
import iconContractual from "../../assets/icons/01-icon-vertragsproduzenten.svg";
import iconCooperation from "../../assets/icons/02-icon-zusammenarbeit.svg";
import Ticker from "./Ticker";

const Cultivation = (props) => {
    const {title, cultivation} = props;
    const {contractualProducer, cooperation, cultivationProducts} = cultivation;
    return (
        <div className="svc-item svc-growing my-5">
            <h3 className="border-bottom border-dark border-3 pb-3">{title}</h3>
            <div className="row">
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <img className="img-thumbnail border-0" src={iconContractual} alt="vertragsproduzenten"/>
                        <h4>{contractualProducer.title}</h4>
                        <p>{contractualProducer.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <img src={iconCooperation} alt="vertragsproduzenten"/>
                        <h4>{cooperation.title}</h4>
                        <p>{cooperation.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <Ticker end={150} />
                        <h4>{cultivationProducts.title}</h4>
                        <p>{cultivationProducts.description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

Cultivation.defaultProps = {
    cultivation: {
        contractualProducer: {
            title: "Vertragsproduzenten",
            description: "20 in Marokko, Israel und Ägypten"
        },
        cooperation: {
            title: "Zusammenarbeit",
            description: "10 Jahre Langfristige Zusammenarbeit."
        },
        cultivationProducts: {
            title: "Produkte",
            description: "stehen in unserem Angebot"
        }
    }
}

export default Cultivation;