import React, {useEffect, useState} from 'react';
import 'bootstrap/js/src/carousel';
import {fetchSlides} from "../../api/api";
import Slide from "./Slide";
import LangNav from "./LangNav";

const Slider = () => {
    const [slides, setSlides] = useState([]);
    useEffect(() => {
        fetchSlides().then(result => setSlides(result.slides));
    }, []);

    return (
        <div id="product-carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {slides.map((slide, idx) =>
                    <button key={`header-slider-button-${idx}`} type="button" data-bs-target="#product-carousel" data-bs-slide-to={idx}
                            className={idx === 0 ? "active" : ""}
                            aria-current={idx === 0 ? "true" : "false"}
                            aria-label={`Slide ${idx + 1}`}/>
                )}
            </div>
            <div className="carousel-inner">
                {slides.map((slide, idx) => <Slide key={slide.hash} slide={slide} isFirstSlide={idx === 0}/>)}
            </div>
            <div className="nav-lang position-absolute d-none d-md-block p-3 pb-0 text-white">
                <LangNav/>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#product-carousel"
                    data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"/>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#product-carousel"
                    data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"/>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Slider;