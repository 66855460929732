import scrollToElement from "scroll-to-element";
import _ from "lodash";

const colors = {
    "citrus": "#C5D935",
    "clementine": "#CA9326",
    "melon": "#79AA80",
    "exotic": "#912F69",
    "dades": "#B0584D",
    "tomato": "#B51F24",
    "beans": "#0E8642",
    "potatoe": "#432626",
    "herbs": "#87C87F"
}

export const getHex = (color) => {
    if (color in colors) {
        return colors[color];
    }

    throw new Error("Color not found");
}

export const scrollTo = (selector) => {
    scrollToElement(selector, {
        offset: 0,
        duration: 800,
        ease: "linear"
    })
};

export const footerTranslation = (locale) => {
    const _locale = _.isEmpty(locale) ? "de" : locale;

    const translations = {
        "de" : {
            "country" : "Schweiz",
            "contact" : "Allgemeiner Kontakt",
            "privacyPolicyLink" : "https://www.privacybee.ch/v/clo5ocidb001fy11kq16sgmmh?lang=de",
            "privacyPolicy" : "Datenschutzerklärung"
        },
        "fr" : {
            "country" : "Suisse",
            "contact" : "contact général",
            "privacyPolicyLink" : "https://www.privacybee.ch/v/clo5ocidb001fy11kq16sgmmh?lang=en",
            "privacyPolicy" : "Privacy Policy"
        },
        "en" : {
            "country" : "Switzerland",
            "contact" : "general contact",
            "privacyPolicyLink" : "https://www.privacybee.ch/v/clo5ocidb001fy11kq16sgmmh?lang=en",
            "privacyPolicy" : "Privacy Policy"
        },
        "es" : {
            "country" : "Suiza",
            "contact" : "contacto general",
            "privacyPolicyLink" : "https://www.privacybee.ch/v/clo5ocidb001fy11kq16sgmmh?lang=en",
            "privacyPolicy" : "Privacy Policy"
        },
        "it" : {
            "country" : "Svizzera",
            "contact" : "contatto generale",
            "privacyPolicyLink" : "https://www.privacybee.ch/v/clo5ocidb001fy11kq16sgmmh?lang=en",
            "privacyPolicy" : "Privacy Policy"
        },
    }

    return translations[_locale];
}