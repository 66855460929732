import React from "react"
import {getImageUrl} from "../../api/util";
import slugify from 'react-slugify';

const Employee = (props) => {
    const {employee, labelTranslations} = props;
    if (employee === undefined) {
        return <></>;
    }

    return (
        <div className="row mb-5 employee" id={slugify(employee.name)}>
            <div className="col-md-4">
                <div className="square">
                    <img src={getImageUrl(employee.image)} className={"img-fluid"} alt={employee.name}/>
                </div>
            </div>
            <div className="col-md-8">
                <div className="employee-card ps-0 ps-md-5">
                    <h3 className="mt-3 mt-md-0">{employee.name}</h3>
                    <p className="lead pb-1" style={{borderBottom: "2px solid black"}}>
                        {employee.function}
                    </p>
                    <div className="info ps-0 ps-md-3">
                        <dl className="row">
                            <dt className="col-sm-4">{labelTranslations.languages}:</dt>
                            <dd className="col-sm-8">{employee.languages}</dd>
                            <dt className="col-sm-4">{labelTranslations.favoriteFruit}:</dt>
                            <dd className="col-sm-8">{employee.favoriteFruit}</dd>
                            <dt className="col-sm-4">{labelTranslations.favoriteVeggie}:</dt>
                            <dd className="col-sm-8">{employee.favoriteVeggie}</dd>
                        </dl>
                        <p className="tel">
                            <a href={`tel:${employee.telephone}`} className="link-dark">{employee.telephone}</a>
                        </p>
                        <p className="email">
                            <a href={`mailto:${employee.email}`} className="link-dark">{employee.email}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Employee;