import React, {useEffect, useState} from "react"
import {getImageUrl} from "../../api/util";
import {useParams} from "react-router-dom";
import {fetchVacancy} from "../../api/api";
import JobAd from "./JobAd";
import _ from "lodash";

const Vacancy = () => {
    const {locale} = useParams();
    const [vacancy, setVacancy] = useState({});

    useEffect(() => {
        fetchVacancy(locale).then(result => setVacancy(result));
    }, [locale]);


    if (_.isEmpty(vacancy)) {
        return <></>;
    }
    return (
        <div className="row mb-5 employee">
            <div className="col-md-4">
                <div className="square">
                    <img src={getImageUrl(vacancy.image)} className={"img-fluid"} alt={vacancy.title}/>
                </div>
            </div>
            <div className="col-md-8">
                <div className="employee-card ps-0 ps-md-5">
                    <h3 className="mt-3 mt-md-0" style={{borderBottom: "2px solid black"}}>{vacancy.title}</h3>
                    <div className="info ps-0">
                        <span>{vacancy.description}</span>

                        <br/>
                        <br/>
                        {_.isArray(vacancy.jobAd) &&
                            vacancy.jobAd.map(ad => <JobAd key={ad.id} {...ad} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vacancy;