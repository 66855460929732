import React from 'react';
import {getImageUrl} from "../../api/util";

const JobAd = ({title, pdf}) => {
    return (
        <div>
            <a target="_blank" rel="noreferrer" href={getImageUrl(pdf)} download>
                <strong>-> {title}</strong>
            </a>
        </div>
    );
};

export default JobAd;