import './App.css';
import './styles/index.scss';
import "bootstrap/js/src/modal";
import Page from "./components/Page";
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import tomato from "./assets/images/tomato.jpeg"
import _ from "lodash";

const randomColor = () => {
    const colors = ["#C5D935", "#CA9326", "#79AA80", "#912F69", "#B0584D", "#B51F24", "#0E8642", "#432626", "#87C87F"];
    return colors[_.random(colors.length - 1)];
};

const NotFound = () => (
    <div style={{backgroundImage: `url(${tomato})`, backgroundSize: 'cover', height: '100vh', backgroundPosition: "center center" }} className={"text-center pt-5"}>
        <h3>404 - Not Found!</h3>
        <Link to="/"><strong>Go Home</strong></Link>
    </div>
);

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/:locale(de|fr|en|es|it)?" exact={true} component={Page} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
}

export default App;
