import React from 'react';
import Slider from "./Slider";
import Topnav from "./Topnav";

const Header = () => {
    return (
        <React.Fragment>
            <Topnav/>
            <Slider/>
        </React.Fragment>
    );
}

export default Header;