import React from "react"
import {get, getImageUrl, } from "../../api/util";
import slugify from 'react-slugify';
import {scrollTo} from "../../util/util";

const getTitles = (titles) => ({
    products: get(titles, "products", "Produkte"),
    origin: get(titles, "origin", "Herkunft"),
    contact: get(titles, "contact", "Ansprechperson")
})

const Item = (props) => {
    const {item} = props;
    const titles = getTitles(props.titles);
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id="af-heading-1">
                <button className={`accordion-button collapsed color-${item.color}`} type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#af-collapse-${item.id}`} aria-expanded="false"
                        aria-controls={`af-collapse-${item.id}`}>
                    <span className={"text-wrapper"}>{item.name}</span>
                </button>
            </h2>
            <div id={`af-collapse-${item.id}`} className="accordion-collapse collapse" aria-labelledby="af-heading-1"
                 data-bs-parent={`#${props.parentId}`}>
                <div className="accordion-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">{titles.products}</th>
                                <th scope="col">{titles.origin}</th>
                                <th scope="col">{titles.contact}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {item.products.map(p => (
                                <tr key={`products-row-${p.id}`}>
                                    <td>{p.name}</td>
                                    <td>{p.origin}</td>
                                    <td>
                                        <button className={"btn btn-link p-0 d-inline"} onClick={() => { scrollTo(`#${slugify(p.contact)}`) }}>
                                            {p.contact}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <a target="_blank" rel="noreferrer" href={getImageUrl(item.availabilityChart)}
                       download><strong>->
                        PDF availability chart</strong></a>
                </div>
            </div>
        </div>
    );
}

const ProductAccordion = (props) => {
    return (
        <div className="products-section products-fruits mb-5 mb-md-6">
            <h2 className="mb-3 mb-md-4">{props.title}</h2>
            <div className="accordion product-accordion mb-3" id={props.id}>
                {props.items.map(i => <Item parentId={props.id} titles={props.tableTitles} key={i.id} item={i}/>)}
            </div>
        </div>
    );
}

export default ProductAccordion;