import React from "react";
import iconPack from "../../assets/icons/03-icon-verpackung.svg";
import iconCert from "../../assets/icons/04-icon-zertifizierung.svg";
import TeaserImage from "./TeaserImage";
import Ticker from "./Ticker";

const Packing = (props) => {
    const {title, packaging, image} = props;
    const {pack, packageHouses, certification} = packaging;

    return (
        <div className="svc-item svc-pack my-5">
            <TeaserImage image={image} />
            <h3 className="border-bottom border-dark border-3 pb-3">{title}</h3>

            <div className="row">
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <img className="img-thumbnail border-0" src={iconPack}
                             alt="packing"/>
                        <h4>{pack.title}</h4>
                        <p>{pack.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <Ticker end={40} />
                        <h4>{packageHouses.title}</h4>
                        <p>{packageHouses.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <img src={iconCert} alt="cert"/>
                        <h4>{certification.title}</h4>
                        <p>{certification.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

Packing.defaultProps = {
    title: "Packung",
    packaging: {
        pack: {
            title: "Verpackung",
            description: "massgeschneidert nach Kundenwunsch"
        },
        packageHouses: {
            title: "Verpackungshäuser",
            description: null
        },
        certification: {
            title: "Zertifizierung",
            description: "GGN, GRASP, IFS und BSCI"
        }
    }
}

export default Packing;