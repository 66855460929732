const {
    REACT_APP_API_URL,
    REACT_APP_API_HEADER_SLIDER,
    REACT_APP_API_TOP_NAVIGATION,
    REACT_APP_API_PRODIVEC,
    REACT_APP_API_PRODUCT_CATEGORIES,
    REACT_APP_API_FOOTER,
    REACT_APP_API_VACANCY
} = process.env;

export const fetchProdivec = (locale) => doFetch(REACT_APP_API_PRODIVEC, locale);
export const fetchSlides = () => doFetch(REACT_APP_API_HEADER_SLIDER);
export const fetchProductCategories = (locale) => doFetch(REACT_APP_API_PRODUCT_CATEGORIES, locale);
export const fetchTopNav = (locale) => doFetch(REACT_APP_API_TOP_NAVIGATION, locale);
export const fetchFooter = () => doFetch(REACT_APP_API_FOOTER);
export const fetchVacancy = (locale) => doFetch(REACT_APP_API_VACANCY, locale);
export const doFetch = (api, locale) => {
    const url = new URL(REACT_APP_API_URL + api);
    if (locale !== undefined) {
        url.searchParams.set('_locale', locale);
    }

    return fetch(url.toString())
        .then(res => res.json());
}