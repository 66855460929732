import React from "react";
import {Link} from "react-router-dom";


const LangNav = () => {
    return (
        <ul className="list-inline list-pipes">
            <li className="list-inline-item">
                <Link to="/">DEUTSCH</Link>
            </li>
            <li className="list-inline-item">
                <Link to="/fr">FRANÇAIS</Link>
            </li>
            <li className="list-inline-item">
                <Link to="/en">ENGLISH</Link>
            </li>
            <li className="list-inline-item">
                <Link to="/es">ESPAÑOL</Link>
            </li>
            <li className="list-inline-item">
                <Link to="/it">ITALIANO</Link>
            </li>
        </ul>
    )
}

export default LangNav;