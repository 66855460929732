import React, {useEffect, useState} from "react"
import logoIfs from "../assets/images/logo-ifs-broker.svg";
import logoGap from "../assets/images/logo-globalgap.svg";
import {fetchFooter} from "../api/api";
import _ from "lodash";
import {getServerUrl} from "../api/util";
import {footerTranslation} from "../util/util";
import {useParams} from "react-router-dom";



const Footer = () => {
    const [footer, setFooter] = useState({});
    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        fetchFooter().then(data => setFooter(data));
    }, []);

    const {locale} = useParams();

    const footerTranslations = footerTranslation(locale);

    return (
        <footer className="container-fluid">
            <div className="row py-4 align-items-center d-none d-md-block">

                <div className="row">
                    <div className="col-md-3 col-12" >
                        <div className="footer-logo">
                            <CertLogos {...footer} />
                        </div>
                        <div className="mt-4">
                          <a target="_blank" href={footerTranslations.privacyPolicyLink}>
                              <span className="text-white">{footerTranslations.privacyPolicy}</span>
                          </a>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <address className="pt-3 pt-md-0">
                            <span className="d-md-block"><strong>Prodivec Swiss AG</strong></span><br/>
                            Bahnhofstr. 37<br/>
                            3427 Utzenstorf<br/>
                            {footerTranslations.country} <br/>
                            <small>MwSt CHE-115.715.108 </small><br/>
                            <small>DE272399685</small>
                        </address>
                    </div>
                    <div className="col-md-3 col-12">
                        <address>
                            <span className="d-md-block"><strong>{footerTranslations.contact}</strong></span><br/>
                            Tel. +41 32 520 01 80<br/>
                            Fax +41 32 520 01 89<br/>
                            info@prodivec.com
                        </address>
                    </div>
                    <div className="col-md-3 col-12">
                        <address>
                            <span className="d-md-block"><strong>Prodivec Europe s.a.s</strong></span><br/>
                            69 avenue Roger Salengro<br/>
                            68100 Mulhouse<br/>
                            France<br/>
                            <small>FR13527837884</small>
                        </address>
                    </div>
                </div>
            </div>

            <div className="row py-4 align-items-center d-sm-block d-md-none">
                <div className="col-12">
                    <address className="pt-3 pt-md-0">
                        <span data-bs-toggle="collapse" data-bs-target="#address-1" aria-expanded="false"
                              aria-controls="address-1" className="d-md-block">
                            <strong className={"footer-collapse-button"}>Prodivec Swiss AG</strong></span>
                        <span id={"address-1"} className="collapse">
                            Bahnhofstr. 37<br/>
                            3427 Utzenstorf<br/>
                            {footerTranslations.country} <br/>
                            <small>MwSt CHE-115.715.108 </small><br/>
                            <small>DE272399685</small>
                        </span>
                    </address>
                </div>
                <div className="col-12">
                    <address>
                        <span data-bs-toggle="collapse" data-bs-target="#address-2" aria-expanded="false"
                              aria-controls="address-2" className="d-md-block"><strong
                            className={"footer-collapse-button"}>Allgemeiner Kontakt</strong></span>
                        <span id="address-2" className="collapse">
                            Tel. +41 32 520 01 80<br/>
                            Fax +41 32 520 01 89<br/>
                            info@prodivec.com
                        </span>
                    </address>
                </div>
                <div className="col-12">
                    <address>
                        <span data-bs-toggle="collapse" data-bs-target="#address-3" aria-expanded="false"
                              aria-controls="address-3" className="d-md-block"><strong
                            className={"footer-collapse-button"}>Prodivec Europe s.a.s</strong></span>
                        <span id="address-3" className={"collapse"}>
                            69 avenue Roger Salengro<br/>
                            68100 Mulhouse<br/>
                            France<br/>
                            <small>FR13527837884</small>
                        </span>
                    </address>
                </div>
                <div className="col-md-3 col-12 footer-logo mb-3">
                    <div className="footer-logo ps-md-3 row">
                        <div className="col-12">
                            <div className="mb-3">
                                <a target="_blank" href={footerTranslations.privacyPolicyLink}>
                                    <span className="text-white">{footerTranslations.privacyPolicy}</span>
                                </a>
                            </div>
                            <CertLogos {...footer} />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

const CertLogos = (props) => {
    const {certificateIFS, certificateGAP} = props;
    return <React.Fragment>
        <CertLogo url={certificateGAP}>
            <img className="img-fluid d-inline-block" src={logoGap} alt=""/>
        </CertLogo>
        <CertLogo url={certificateIFS}>
            <img className="img-fluid d-inline-block" src={logoIfs} alt=""/>
        </CertLogo>
    </React.Fragment>
}

const CertLogo = (props) => {
    const {url} = props;
    if (_.isEmpty(url)) {
        return <React.Fragment>
            {props.children}
        </React.Fragment>
    }

    return <a href={getServerUrl(url.url)} target="_blank" rel="noopener noreferrer">
        {props.children}
    </a>;
}

export default Footer;