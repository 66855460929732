import React from "react"
import TeaserImage from "./TeaserImage";
import Ticker from "./Ticker";

const Sales = (props) => {

    const {title, image, sales} = props;
    const {supermarkets, countries, shipments} = sales;

    return (
        <div className="svc-item svc-pack my-5">
            <TeaserImage image={image} />
            <h3 className="border-bottom border-dark border-3 pb-3">{title}</h3>

            <div className="row">
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <Ticker end={22} />
                        <h4>{supermarkets.title}</h4>
                        <p>{supermarkets.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <Ticker end={16} />
                        <h4>{countries.title}</h4>
                        <p>{countries.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <Ticker end={2800} />
                        <h4>{shipments.title}</h4>
                        <p>{shipments.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

Sales.defaultProps = {
    title: "Verkauf",
    sales: {
        supermarkets: {
            title: "Supermärkte",
            description: "zählen zu unseren zufriedenen Kunden"
        },
        countries: {
            title: "Länder",
            description: "verkaufen unsere Produkte"
        },
        shipments: {
            title: "Sendungen",
            description: "werden pro Jahr getätigt"
        }
    }
}

export default Sales;