import React from 'react';
import ProductAccordion from "./ProductAccordion";
import SimpleProduct from "./SimpleProduct";
import {get, getImageUrl} from "../../api/util";
import Section from "../Section";
import Container from "../Container";

const getTitles = (titles) => ({
    main: get(titles, "main", "Produkte"),
    fruit: get(titles, "fruit", "Früchte"),
    veggie: get(titles, "veggie", "Gemüse"),
    additional: get(titles, "additional", "Sweetheart")
});

const ProductSection = (props) => {

    const {products} = props;
    if (products === undefined) {
        return <></>;
    }

    const {main, fruit, veggie, additional} = getTitles(products.titles);
    const {teaserImage, additionalTeaserImage} = get(products, "productImages", {});

    return (
        <div className={"mt-4 mt-md-5"}>
            <Container>
                <Section id={"products"} title={main}>
                    <ProductAccordion id="fruit-accordion" title={fruit} tableTitles={products.tableTitles}
                                      items={products.fruitAccordion}/>
                    <ProductAccordion id="veggie-accordion" title={veggie} tableTitles={products.tableTitles}
                                      items={products.veggieAccordion}/>
                    <SimpleProduct title={additional} image={getImageUrl(additionalTeaserImage)}/>
                </Section>
            </Container>
            <div>
                {teaserImage && (<img src={getImageUrl(teaserImage)} className="img-fluid w-100" alt="Product Teaser"/>)}
            </div>
        </div>
    );
};

export default ProductSection;