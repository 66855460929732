import React from "react";

const Section = (props) => {
    return (
        <React.Fragment>
            <h1 id={props.id} className="text-center mb-5 mb-md-6">{props.title.toUpperCase()}</h1>
            {props.children}
        </React.Fragment>
    );
}

export default Section;