import React from "react";

const SimpleProduct = (props) => {
    return (
        <div className="products-section sweetheart mb-5 mb-md-6">
            <h2>{props.title.toUpperCase()}</h2>
            {props.image && (<img src={props.image} alt={props.title} className="img-fluid"/>)}
        </div>
    );
}

export default SimpleProduct;