import React from "react";
import {getImageUrl} from "../../api/util";

const Slide = (props) => {
    const {slide, isFirstSlide} = props;
    return (
        <div key={slide.id} className={`carousel-item ${isFirstSlide ? "active" : ""}`}>
            <img src={getImageUrl(slide)} alt={slide.name} />
        </div>
    );
}

export default Slide;