import React from "react";
import Section from "../Section";
import Container from "../Container";
import {get, getImageUrl} from "../../api/util";
import Employee from "./Employee";
import Vacancy from "./Vacancy";

const BusinessSection = (props) => {
    const {business} = props;
    if (business === undefined) {
        return <></>;
    }

    const {groupPhoto, employees, labelTranslations} = business;

    return (
        <div className={"mt-4 mt-md-5"}>
            <div>
                {groupPhoto && (<img src={getImageUrl(groupPhoto)} className="img-fluid mb-4 mb-md-6"
                                     alt={get(groupPhoto, "name", "Groupphoto")}/>)}
            </div>
            <Container>
                <Section id={"business"} title={get(business, "title", "Unternehmen")}/>
                {employees.map(e => <Employee key={e.id} labelTranslations={labelTranslations} employee={e}/>)}
                <Vacancy />
            </Container>
        </div>
    );
}

export default BusinessSection;