import React from "react";
import Container from "../Container";
import Section from "../Section";
import Cultivation from "./Cultivation";
import Packing from "./Packing";
import ExportImport from "./ExportImport";
import Logistics from "./Logistics";
import Sales from "./Sales";
import {get} from "../../api/util";

const getTitles = (titles) => ({
    main: get(titles, "main", "Service"),
    cultivation: get(titles, "cultivation", "Anbauplanung"),
    packaging: get(titles, "packaging", "Packung"),
    exportImport: get(titles, "exportImport", "Export / Import"),
    logistics: get(titles, "logistics", "Logistik"),
    sales: get(titles, "sales", "Verkauf")
})

const getServices = (service) => ({
    cultivation: get(service, "cultivation"),
    packaging: get(service, "packaging"),
    images: get(service, "images"),
    exportImport: get(service, "exportImport"),
    logistics: get(service, "logistics"),
    sales: get(service, "sales")
});

const ServicesSection = ({service}) => {

    if (service === undefined) {
        return <></>;
    }
    const titles = getTitles(get(service, "titles"));

    const {cultivation, packaging, images, exportImport, logistics, sales} = getServices(service);

    return (
        <div className={"mt-4 mt-md-5"}>
            <Container>
                <Section id={"service"} title={titles.main}>
                    <Cultivation title={titles.cultivation} cultivation={cultivation}/>
                    <Packing title={titles.packaging} packaging={packaging} image={get(images, "packing")}/>
                    <ExportImport title={titles.exportImport} exportImport={exportImport}
                                  image={get(images, "exportImport")}/>
                    <Logistics title={titles.logistics} logistics={logistics} image={get(images, "logistics")}/>
                    <Sales title={titles.sales} sales={sales} image={get(images, "sales")}/>
                </Section>
            </Container>
        </div>
    );
};

ServicesSection.defaultProps = {
    service: {}
}

export default ServicesSection;