import React, {useEffect, useRef, useState} from 'react';
import logo from '../../assets/images/prodivec-logo.svg';
import {fetchTopNav} from "../../api/api";
import {useHistory, useParams} from 'react-router-dom';
import {Modal} from "bootstrap";
import _ from "lodash";
import {scrollTo} from "../../util/util";


const Topnav = () => {
    const {locale} = useParams();
    const modalRef = useRef()
    const [modal, setModal] = useState(null)
    const [navItems, setNavItems] = useState([]);
    const history = useHistory();

    const scrollToEl = (selector) => {
        setTimeout(() => modal.hide(), 200);
        scrollTo(selector);
    }

    const switchLang = (lang) => {
        if (_.isEmpty(lang)) {
            history.push(`/`);
        } else {
            history.push(`/${lang}`);
        }
    }

    useEffect(() => {
        setModal(new Modal(modalRef.current));
        fetchTopNav(locale).then(result => setNavItems(result.items));
    }, [locale]);


    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg fixed-top position-absolute navbar-light">
                <a className="navbar-brand logo col-8 col-md-4 col-lg-3 p-1 p-lg-3 ms-2" href="/">
                    <img className="img-fluid logo1" src={logo} alt="logo"/>
                </a>
                <button className="navbar-toggler me-2" type="button"
                        onClick={() => modal.show()}>
                    <span className="navbar-toggler-icon"/>
                </button>

                <div className="collapse navbar-collapse sub-menu-bar align-self-start" id="navbarCollapse">
                    <ul id="nav" className="navbar-nav ms-auto">
                        {navItems.map(item => (
                            <li key={item.id} className="nav-item">
                                <button className={"nav-link btn btn-link"} onClick={() => { scrollToEl(`#${item.anchor}`) }}>
                                    {item.label}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>

            <div className="modal fade" ref={modalRef} id="exampleModal" tabIndex="-1"
                 aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close float-end" data-bs-dismiss="modal"
                                    aria-label="Close"/>
                            <ul className="navbar-nav ms-auto">
                                {navItems.map(item => (
                                    <li key={item.id} className="nav-item">
                                        <button className={"nav-link btn btn-link"} onClick={() => { scrollToEl(`#${item.anchor}`) }}>
                                            {item.label}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <div className="nav-lang p-2 text-white">
                                <ul className="list-inline mb-0 list-pipes text-end">
                                    <li className="list-inline-item">
                                        <button className={"btn btn-link"} onClick={() => { switchLang() }}>
                                            DE
                                        </button>
                                    </li>
                                    <li className="list-inline-item">
                                        <button className={"btn btn-link"} onClick={() => { switchLang("fr") }}>
                                            FR
                                        </button>
                                    </li>
                                    <li className="list-inline-item">
                                        <button className={"btn btn-link"} onClick={() => { switchLang("en") }}>
                                            EN
                                        </button>
                                    </li>
                                    <li className="list-inline-item">
                                        <button className={"btn btn-link"} onClick={() => { switchLang("es") }}>
                                            ES
                                        </button>
                                    </li>
                                    <li className="list-inline-item">
                                        <button className={"btn btn-link"} onClick={() => { switchLang("it") }}>
                                            IT
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Topnav;