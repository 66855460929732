import React, {useEffect, useState} from 'react';
import Header from "./header/Header";
import ProductSection from "./product/ProductSection";
import {useParams} from "react-router-dom";
import {fetchProdivec} from "../api/api";
import ServicesSection from "./services/ServicesSection";
import Footer from "./Footer";
import BusinessSection from "./business/BusinessSection";
import Loader from "react-loader-spinner";
import _ from "lodash";
import ScrollToTop from "react-scroll-to-top";
import { ImArrowUp2 } from "react-icons/im"

const randomColor = () => {
    const colors = ["#C5D935", "#CA9326", "#79AA80", "#912F69", "#B0584D", "#B51F24", "#0E8642", "#432626", "#87C87F"];
    return colors[_.random(colors.length - 1)];
};


const Page = () => {

    let {locale} = useParams();
    const [pageData, setPageData] = useState({});
    const [loading, setLoading] = useState(true)
    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        fetchProdivec(locale).then(data => {
            setPageData(data);
            setLoading(false)
        }).catch(e => setLoading(false))
    }, [locale])

    return (
        <React.Fragment>
            {loading && (
                <div className={"loader-bg"}><Loader className={"loader"} type="Oval" color={randomColor()} height={150}
                                                     width={150}/></div>)}
            <ScrollToTop color={randomColor()} smooth component={<ImArrowUp2 />} />
            <Header/>
            <ProductSection products={pageData.products}/>
            <ServicesSection service={pageData.service}/>
            <BusinessSection business={pageData.business}/>
            <Footer/>
        </React.Fragment>
    );
};

export default Page;