import React from "react";
import iconQuality from "../../assets/icons/07-icon-qualitaet.svg";
import TeaserImage from "./TeaserImage";
import Ticker from "./Ticker";

const Logistics = (props) => {
    const {image, title, logistics} = props;
    const {quality, distance, container} = logistics;

    return (
        <div className="svc-item svc-pack my-5">
            <TeaserImage image={image} />
            <h3 className="border-bottom border-dark border-3 pb-3">{title}</h3>

            <div className="row">
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <img className="img-thumbnail border-0" src={iconQuality} alt="packing"/>
                        <h4>{quality.title}</h4>
                        <p>{quality.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <Ticker end={60} />
                        <h4>{distance.title}</h4>
                        <p>{distance.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <Ticker end={350} />
                        <h4>{container.title}</h4>
                        <p>{container.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

Logistics.defaultProps = {
    title: "Logistik",
    logistics: {
        quality: {
            title: "Qualität",
            description: "Wir haben unsere eigene Qualitätskontrolle"
        },
        distance: {
            title: "Kilometer",
            description: "misst der Weg zu unserem am weitesten entfernten Kunden"
        },
        container: {
            title: "Schiffscontainer",
            description: "werden jedes Jahr verschifft"
        }
    }
}

export default Logistics;