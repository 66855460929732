import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

window.onbeforeunload = () => {
    window.scrollTo(0, 0);
}

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
