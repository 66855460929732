import React from "react";
import iconCountries from "../../assets/icons/05-icon-laender.svg";
import iconLanguages from "../../assets/icons/06-icon-sprachen.svg";
import TeaserImage from "./TeaserImage";
import Ticker from "./Ticker";

const ExportImport = (props) => {

    const {title, image, exportImport} = props;
    const {products, languages, amount} = exportImport;

    return (
        <div className="svc-item svc-pack my-5">
            <TeaserImage image={image} />
            <h3 className="border-bottom border-dark border-3 pb-3">{title}</h3>

            <div className="row">
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <img className="img-thumbnail border-0" src={iconCountries} alt="packing"/>
                        <h4>{products.title}</h4>
                        <p>{products.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <img src={iconLanguages} alt="cert"/>
                        <h4>{languages.title}</h4>
                        <p>{languages.description}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="service-col text-center">
                        <Ticker end={15000} formattingFn={(x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "`") } />
                        <h4>{amount.title}</h4>
                        <p>{amount.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

ExportImport.defaultProps = {
    title: "Export / Import",
    exportImport: {
        products: {
            "title": "Produkte",
            "description": "aus 26 Herkunftsländern"
        },
        languages: {
            title: "Sprachen",
            description: "Wir sprechen: DE, EN, FR, ES, IT, PT, RU"
        },
        amount: {
            title: "Tonnen",
            description: "werden jedes Jahr exportiert"
        }
    }
}

export default ExportImport